export default [
  {
    path: '/programmi/fse-2021-2027',
    redirect: '/programmi/fse/pr-fse-21-27-programma-sardegna-fse-2021-2027'
  },
  {
    path: '/programmi/fse-2021-2027/priorita-obiettivi/progetti-strategici-unione-europea',
    redirect: '/programmi/fse/operazioni-di-importanza-strategica'
  },
  {
    path: '/programmi/fse-2021-2027/calendario-inviti',
    redirect: '/programmi/fse/calendario-inviti-e-documentazione'
  },
  {
    path: '/programmi/fse-2021-2027/punto-di-contatto',
    redirect: '/programmi/fse/articolo/punto-di-contatto'
  }
];


