import '@/assets/vendor/font-awesome/css/font-awesome.css';
import '@/assets/styles/bootstrap-italia-custom.min.css';
import globalDirectives from "./globalDirectives";


export default {
  install(Vue) {
    Vue.use(globalDirectives);
  }
};
