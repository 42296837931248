<template>
   <layout-default>   
      <div v-for="(item, index) in layout" :key="`${index}-block`">
         <BaseBlock :tipologia="item.tipo.toLowerCase()" :item="item" v-if="item.attivo"/>
      </div>
   </layout-default>
</template>


<script>
   import BaseBlock from '@/components/blocks/BaseBlock.vue';   
   import LayoutDefault from '@/layout/LayoutDefault.vue';
   import axios from 'axios';

   export default {
      name: `Home`,
      components: {
         LayoutDefault,   
         BaseBlock,
      },
         
      data(){
         return{
            errors: [],
            layout: []
         }
      },
      
      async beforeMount() {
         if(this.$store.getters.getLayout.length > 0){
            this.layout = this.$store.getters.getLayout;
         }
         else{
            await axios.get(process.env.VUE_APP_HP_LAYOUT)
            .then((response) => {
               this.layout = response.data[0].bloccoPaginaList;
            })           
         }
      },

      created(){
         this.$root.setTitle('Home');
      }
   };
</script>