<template>
   <div>
      <section>
         <div class="container pt-2 pb-4">
            <div class="row justify-content-between">

               <div class="col-12 col-md-6 mb-4">
                  <h2>Cerca</h2>
                  <searchArea :full="'full'" :where="'intro'" :inactive="true" :keywords="query" v-on:queryWatcher="setQuery($event)" v-on:clearSearch="clearSearch()" ref="searchArea" />
               </div>
            </div>
         </div>
      </section>
      <section>
         <div class="container">
            <div class="d-block w-100 my-3">
               <div class="d-flex small align-items-center justify-content-between">
                  <div class="text-left">Risultati trovati <span class="py-1 px-2 font-weight-semibold complementary-2-bg complementary-2-border bordered ml-2">{{ pager.totalResults }}</span></div>
                  <div class="align-items-center d-flex mt-5">
                     <span>Ordina per </span>
                     <b-form-select class="bg-white border-bottom bordered ml-1 form-control-sm" value-field="value" text-field="text" v-model="sortBy.selected" :options="sortBy.sorting" @change="changeSorting" />
                  </div>
               </div>
            </div>

            <div class="mt-4">
               <div class="row">
                  <ListaFiltri :filtri="aggregations" :filterSelected="filters" v-on:listen-change-filter="changeFilters($event)" :who="who"></ListaFiltri>

                  <ListaPane :results="hits" ref="searchContainer" :searchCard="true" v-if="pager.totalResults > 0 && results !== null"></ListaPane>

                  <div class="col-12 col-sm-9 col-md-6 col-lg-9" v-if="pager.totalResults == 0 && results !== null">
                     <div class="alert alert-danger m-4 mr-auto ml-auto ">
                        La ricerca non ha prodotto risultati in nessun risultato.
                     </div>
                  </div>

               </div>
               <div class="row">
                  <Pager :pager="pager" use-router v-if="pager.totalResults > 0" v-on:listenChangePage="changePage($event)" v-on:listenLimitChange="setLimit($event)" />
               </div>
            </div>
         </div>
         <div class="container">
            <div class="d-block w-100 my-3">
               <div class="d-flex small align-items-center justify-content-between">
               </div>
            </div>
         </div>
      </section>
   </div>
</template>

<script>
import { doSearch } from '@/service/search.js';
import searchArea from "@/components/SearchArea.vue"
import ListaFiltri from '@/components/lists/ListaFiltri';
import ListaPane from '@/components/lists/ListaPane';
import Pager from '@/components/Pager';

export default {
   name: "Ricerca",

   components: {
      searchArea,
      ListaFiltri,
      ListaPane,
      Pager
   },
   data() {
      return {
         query: '',
         results: null,
         fromPage: 0, //Page
         hits: null,
         who: "ricerca",
         aggregations: null,
         filters: {
            period: {
               dal: null,
               al: null
            },
            filtri: []
         },
         sortBy: {
            selected: { "_score": { "order": "desc" } },
            sorting: [
               { value: { "_score": { "order": "desc" } }, text: 'dal più rilevante' },
               { value: { "data.titolo.iv.keyword": { "order": "asc" } }, text: 'dal titolo A-Z' },
               { value: { "data.titolo.iv.keyword": { "order": "desc" } }, text: 'dal titolo Z-A' },
               { value: { "data.dataPubblicazione.iv": { "order": "desc" } }, text: 'dal più recente' },
               { value: { "data.dataPubblicazione.iv": { "order": "asc" } }, text: 'dal meno recente' },
            ]
         },
         sort: [{ "_score": { "order": "desc" } }],
         pager: {
            currentPage: 1,
            totalResults: 0,
            from: 0,
            limit: 12
         },
      }
   },

   mounted() {
      // Keywords search
      if (this.$route.query.q !== undefined || this.$route.query.keywords !== undefined || this.$route.params.keywords != '') {
         this.query = this.$route.query.q != '' ? this.$route.query.q : this.$route.query.keywords !== '' ? this.$route.query.keywords : this.$route.params.keywords;
      }
      //topic
      if (this.$route.query.topic !== undefined && this.$route.query.topic) {
         if (this.$route.query.topic.indexOf(',') > 0) {
            let filterTopic = this.$route.query.topic.split(',');
            for (let topic of filterTopic) {
               this.filters.filtri.push('TOPIC:' + topic)
            }
         } else {
            this.filters.filtri.push('TOPIC:' + this.$route.query.topic)
         }
      }

      if (this.$route.query.programma !== undefined && this.$route.query.programma) {
         //programma
         this.filters.filtri.push('PROGRAMMA:' + this.$route.query.programma)
      }

      if (this.$route.query.progetti !== undefined && this.$route.query.progetti) {
         //preogetti strategici
         this.filters.filtri.push('PROGETTI_STRATEGICI:' + this.$route.query.progetti)
      }
      if (this.$route.query.servizio_responabile !== undefined && this.$route.query.servizio_responabile) {
         //preogetti strategici
         this.filters.filtri.push('SERVIZIO_RESPONSABILE:' + this.$route.query.servizio_responabile)
      }
      this.search();
      this.$root.setMetaTags({ 'titolo': "Ricerca" });
   },

   methods: {
      setQuery(event) {
         this.query = event;
         if (this.query !== undefined && this.query !== '') {
            this.search();
         }
      },

      searchFilter: async function () {
         this.results = await doSearch('', this.pager.from, this.pager.limit, this.filters, this.sort)
         this.pager.totalResults = this.results.total.value
         this.hits = this.results.posts
         this.aggregations = this.results.aggregations
      },

      clearFilters() {
         this.filters.period.dal = this.filters.period.al = null;
         this.filters.filtri = [];
      },

      clearSearch() {
         this.query = ''
         this.clearFilters()
         this.results = null
         this.hits = null
         this.aggregations = null
         this.pager.currentPage = 1
         this.executeSearch();
      },

      async changeFilters(filtri) {
         this.resetPager()
         this.filters = filtri;
         this.executeSearch();
      },

      changePage(value) {
         this.pager.from = this.pager.limit * (value - 1);
         this.pager.currentPage = value;
         this.executeSearch();
      },

      changeSearchTerm(value) {
         this.terms = value;
         this.resetPager()
         this.executeSearch();
      },

      changeSorting(event) {
         if (event == '_score') {
            this.sort = [{ "_score": { "order": "desc" } }];
         }
         else {
            this.sort = event;
         }
         this.executeSearch();
      },

      resetPager(){
         this.pager.currentPage = 1
         this.pager.totalResults = 0
         this.pager.from = 0          
      },

      setLimit(value) {
         this.pager.limit = value;
         this.search();
      },

      search: async function () {
         return await this.executeSearch()
      },

      executeSearch: async function () {
         this.results = await doSearch(this.query, this.pager.from, this.pager.limit, this.filters, this.sort)
         this.pager.totalResults = this.results.total.value         
         this.hits = this.results.posts
         this.aggregations = this.results.aggregations
      },
   },

   watch: {
      currentPage: {
         handler: function () {
            this.fetchData().catch(error => {
               console.error(error)
            })
         }
      }
   }
}

</script>
<style>
#filterTabs .nav-item .nav-link {
   width: 100%;
}

input[type="search"]:placeholder-shown+.input-group-append>.reset {
   display: none;
}

.input-group-append.reset {
   position: unset;
}

.custom-select {
   border: 1px solid #CDCDCD
}

[v-cloak] {
   display: none;
}
</style>