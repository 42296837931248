<template>
   <main>
      <section class="text-center">
         <div class="container py-3 text-left">
            <div class="col-12 col-md-12 col-lg-8">
              <h2 class="h1">404 Pagina non trovata</h2>

              <a class="btn btn-primary my-4" href="/">Torna alla homepage</a>

              <div class="font-normal mt-4">
                Siamo spiacenti, la pagina richiesta non è stata trovata.
              </div>
              <div class="font-normal mt-4">
                Dal {{data_online}} è online una nuova versione del sito Sardegna Programmazione, la risorsa web cercata potrebbe non esistere, non essere più disponibile, essere stata rimossa o aver cambiato indirizzo.
              </div>
              <div class="font-normal mt-4">
                Per trovare il contenuto che cerchi puoi, a partire dalla home page del sito, utilizzare il motore di ricerca o navigare nelle sezioni principali:
              </div>
              <ul class="mt-3">
                <li class="font-weight-semibold"><a href="https://www.sardegnaprogrammazione.it/strategia-2021-2027" class="Strategia 2021-2027">Strategia 2021-2027</a></li>
                <li class="font-weight-semibold"><a href="https://www.sardegnaprogrammazione.it/programmi" title="Programmi">Programmi</a></li>
                <li class="font-weight-semibold"><a href="https://www.sardegnaprogrammazione.it/opportunita-di-finanziamento" title="Opportunità di finanziamento">Opportunità di finanziamento</a></li>
                <li class="font-weight-semibold"><a href="https://www.sardegnaprogrammazione.it/novita" title="Novità">Novità</a></li>
              </ul>
              <div>
                I contenuti di archivio e quelli relativi alle precedenti programmazioni sono disponibili nella <b>versione di archivio</b> del sito raggiungibile al seguente indirizzo<br/>
                <a href="https://old.sardegnaprogrammazione.it/">https://old.sardegnaprogrammazione.it/</a>
              </div>
          </div>
        </div>
      </section>
   </main>
</template>
<script>
   export default {
      name: "NotFound",
      created: function() {
        this.$root.setTitle('Pagina non trovata')
        this.$root.setImageArea(false)
        this.$root.setBreadcrumbLast('404 Pagina non trovata')
        const metaRobots = document.createElement('meta');
        metaRobots.name = 'robots';
        metaRobots.content = 'noindex, nofollow';
        document.head.appendChild(metaRobots);
      },
      data(){
        return{
          data_online: process.env.VUE_APP_SWITCH_OFF
        }
      },
      unmounted: function(){
        this.$root.setBreadcrumbLast('')
        document.head.querySelector("[name='robots']").remove()
        console.log(document.head.querySelector("[name='robots']"))
      }
   };
</script>
<style scoped>
   main{
      font-size:1.5rem;
         background-image: url("~@/assets/img/404.jpg");
          background-position: right -120px;
          background-repeat: no-repeat;
          background-size:40%;
          background-color:#003557;
          font-size:18px;
          position:relative;
        }
   section{
            background: rgba(255,255,255, 0.7);
            position:relative;
            top:0%;
            width:100%;
            vertical-align: center;
            padding-top:30px;
            padding-bottom:60px;
          }
   h3{ font-size:2rem; text-decoration: underline;}
   footer{ margin-top:0px }
</style>