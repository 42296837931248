import axios from 'axios';
axios.defaults.headers.common['Access-Control-Allow-Origin'] = false

export function getDate(dateMod, format) {
   var d = new Date(dateMod);
   format = (format != '' ? format : 'd-m-y');

   if (format === 'd-m') {
      return d.getDate() + ' ' + getMonthName(d.getMonth()).substring(0, 3);
   }
   else if (format === 'd-m-y t') {
      return d.getDate() + ' ' + getMonthName(d.getMonth());
   }
   else {
      return d.getDate() + ' ' + getMonthName(d.getMonth()) + ' ' + d.getFullYear();
   }
}

function getMonthName(monthInt) {
   const monthNames = ["gennaio", "febbraio", "marzo", "aprile", "maggio", "giugno", "luglio", "agosto", "settembre", "ottobre", "novembre", "dicembre"];
   return monthNames[monthInt];
}

function getPayload(terms, who, from, limit, filters, sort) {
   let jsonObj = {
      "size": limit,
      "from": from,
      "sort": sort,
      "aggs": {
         "PROGRAMMA": {
            "terms": { "field": "data.programma.iv.keyword", "order": { "_key": "asc" } }
         },
         "PROGETTI_STRATEGICI": {
            "terms": { "field": "data.progettiStrategici.iv.keyword", "order": { "_key": "asc" } }
         },
         "TOPIC": {
            "terms": { "field": "data.topic.iv.keyword", "order": { "_key": "asc" } }
         },
      },
      "track_scores": true,
      "_source": {
         "excludes": ["timestamp", "appId", "createdBy", "actor", "version", "partition"]
      },
      "query": {
         "bool": {
            "must": [{
               "term": {
                  "schemaName": who
               }
            },
            {
               "term": {
                  "status": "published"
               }
            }],
            "filter": {
               "bool": {
                  "should": [
                  ],
                  "must_not": [
                     { "term": { "schemaName.keyword": "struttura-organizzativa" } },
                     { "term": { "schemaName.keyword": "contatto-rubrica" } }
                  ]
               }
            }
         }
      }
   }

   if (who == 'invito') {
      jsonObj.aggs = {
         "MONTHS": {
            "date_histogram": {
               "field": "data.calendarioEventi.iv.dataDal",
               "calendar_interval": "1M",
               "format": "yyyy - M",
               "min_doc_count": 1
            }
         },
         "PROGRAMMA": {
            "terms": { "field": "data.programma.iv.keyword", "order": { "_key": "asc" } }
         },
         "PRIORITÀ": {
            //"multi_terms": {
            //    "terms": [
            //        {"field": "data.priorita.iv.number.keyword"},
            //        {"field": "data.priorita.iv.titolo.keyword"}
            //    ]
            //}            
            "terms": { "field": "data.priorita.iv.titolo.keyword", "order": { "_key": "asc" } }
         },
         "AREA_GEOGRAFICA": {
            "terms": { "field": "data.calendarioEventi.iv.areaGeografica.areaGeografica.keyword", "order": { "_key": "asc" } }
         },
         /* "OPERAZIONI_DI_IMPORTANZA STRATEGICA": {
            "terms": { "field": "data.progettiStrategici.iv.keyword", "order": { "_key" : "asc" }  }
          },
          "SETTORI_DI_INTERVENTO": {
            "terms": { "field": "data.obiettiviSpecifici.iv.campoIntervento.campoIntervento.keyword", "order": { "_key" : "asc" }  }
          },     
          "SOGGETTI_AMMISSIBILI": {
            "terms": { "field": "data.obiettiviSpecifici.iv.campoIntervento.tipologiaRichiedente.tipologiaRichiedente.keyword", "order": { "_key" : "asc" }  }
          },   */
         "SERVIZIO_RESPONSABILE": {
            "terms": { "field": "data.strutturaOrganizzativa.iv.keyword", "order": { "_key": "asc" } }
         },
         "TOPIC": {
            "terms": { "field": "data.topic.iv.keyword", "order": { "_key": "asc" } }
         }
      };
   }

   if (terms !== '') {
      jsonObj.query.bool.must.push({
         "simple_query_string": {
            "query": terms + '*',
            "flags": "ALL",
            "fuzzy_transpositions": true,
            "fuzzy_max_expansions": 5,
            "fuzzy_prefix_length": 0,
            "minimum_should_match": 1,
            "default_operator": "or",
            "lenient": true,
            "analyzer": "standard",
            "quote_field_suffix": "",
            "analyze_wildcard": false,
            "auto_generate_synonyms_phrase_query": true,
         },
      })
   }

   if (filters.filtri.length > 0 || (filters.period.al !== null && filters.period.dal !== null)) {
      if (filters.filtri.length > 0) {
         for (let filtro of filters.filtri) {
            filtro = filtro.split(':')

            switch (filtro[0]) {
               case 'SETTORI_DI_INTERVENTO':
                  filtro[0] = 'data.obiettiviSpecifici.iv.campoIntervento.campoIntervento.keyword';
                  break;
               case 'PRIORITÀ':
                  filtro[0] = 'data.priorita.iv.titolo.keyword';
                  break;
               case "PROGRAMMA":
                  filtro[0] = 'data.programma.iv.keyword';
                  break;
               case 'OPERAZIONI_DI_IMPORTANZA STRATEGICA':
                  filtro[0] = 'data.progettiStrategici.iv.keyword';
                  break;
               case "AREA_GEOGRAFICA":
                  filtro[0] = 'data.calendarioEventi.iv.areaGeografica.areaGeografica.keyword';
                  break;
               case "PROGETTI_STRATEGICI":
                  filtro[0] = 'data.progettiStrategici.iv.keyword';
                  break;
               case "OPERAZIONI_DI_IMPORTANZA STRATEGICA":
                  filtro[0] = 'data.progettiStrategici.iv.keyword';
                  break;
               case 'SERVIZIO_RESPONSABILE':
                  filtro[0] = 'data.strutturaOrganizzativa.iv.keyword';
                  break;
               case "SOGGETTI_AMMISSIBILI":
                  filtro[0] = 'data.obiettiviSpecifici.iv.campoIntervento.tipologiaRichiedente.tipologiaRichiedente.keyword';
                  break;
               case 'TAG':
                  filtro[0] = 'data.topic.iv.keyword';
                  break;
               case 'TOPIC':
                  filtro[0] = 'data.topic.iv.keyword';
                  break;
            }

            let padreFilter = filtro[0];
            let filterTerm = filtro[1];

            var obj = {};
            obj[padreFilter] = filterTerm;
            jsonObj.query.bool.filter.bool.should.push({
               "term": obj,
            });
         }
      }
      if (filters.period.al !== null && filters.period.dal !== null) {
         let dataDalString = new Date(filters.period.dal.toDateString())
         let dataAlString = new Date(filters.period.al.toDateString());

         let dataDal = dataDalString.getFullYear() + '-' + String((dataDalString.getMonth() + 1) + '').padStart(2, '0') + '-' + String(dataDalString.getDate()).padStart(2, '0');
         let dataAl = dataAlString.getFullYear() + '-' + String((dataAlString.getMonth() + 1) + '').padStart(2, '0') + '-' + String(dataAlString.getDate()).padStart(2, '0');

         if (who == 'invito') {
            jsonObj.query.bool.filter.bool.should.push({ "range": { "data.calendarioEventi.iv.dataDal": { "gte": dataDal, "lte": dataAl, "format": "yyyy-MM-dd" } } })
         }
         else {
            jsonObj.query.bool.filter.bool.should.push({ "range": { "data.dataPubblicazione.iv": { "gte": dataDal, "lte": dataAl, "format": "yyyy-MM-dd" } } })
         }
      }
   }
   else {
      jsonObj.query.bool.filter.bool.should = []
   }

   return jsonObj;
}

function getRubricaPayload(terms, from, limit, filters, sort) {
   let jsonObj = {
      "size": limit,
      "from": from,
      "track_scores": true,
      "_source": {
         "excludes": ["timestamp", "appId", "createdBy", "actor", "version", "partition"]
      },
      "sort": sort,
      "query": {
         "bool": {
            "must": [
               {
                  "simple_query_string": {
                     "query": terms + '*',
                     "flags": "ALL",
                     "fields": ["data.nome.iv", "data.cognome.iv", "data.emailList.iv", "data.telefonoList.iv", "data.strutturaOrganizzativa.iv"],
                     "fuzzy_transpositions": true,
                     "fuzzy_max_expansions": 50,
                     "fuzzy_prefix_length": 0,
                     "minimum_should_match": 1,
                     "default_operator": "or",
                     "lenient": true,
                     "analyzer": "standard",
                     "quote_field_suffix": "",
                     "analyze_wildcard": false,
                     "auto_generate_synonyms_phrase_query": true,
                  }
               },
               {
                  "match": {
                     "schemaName": "rubrica"
                  }
               },
               {
                  "match": {
                     "status": "published"
                  }
               }
            ]
         }
      }
   }
   return jsonObj;
}

function getPayloadSearch(terms, from, limit, filters, sort) {
   if (terms == undefined) { terms = '' }
   let jsonObj = {
      "size": limit,
      "from": from,
      "track_scores": true,
      "aggs": {
         "PROGRAMMA": {
            "terms": { "field": "data.programma.iv.keyword", "order": { "_key": "asc" } }
         },
         "TIPOLOGIA": {
            "terms": { "field": "schemaName.keyword", "order": { "_key": "asc" } }
         },
         "TOPIC": {
            "terms": { "field": "data.topic.iv.keyword", "order": { "_key": "asc" } }
         },
      },
      "sort": sort,
      "query": {
         "bool": {
            "must": [{
               "simple_query_string": {
                  "query": terms + '*',
                  "flags": "ALL",
                  "fuzzy_transpositions": true,
                  "fuzzy_max_expansions": 50,
                  "fuzzy_prefix_length": 0,
                  "minimum_should_match": 1,
                  "default_operator": "or",
                  "lenient": true,
                  "analyzer": "standard",
                  "quote_field_suffix": "",
                  "analyze_wildcard": false,
                  "auto_generate_synonyms_phrase_query": true,
               }
            }],
            "filter": {
               "bool": {
                  "should": [
                  ],
                  "must_not": [
                     { "term": { "schemaName.keyword": "struttura-organizzativa" } },
                     { "term": { "schemaName.keyword": "progetto-strategico" } },
                     { "term": { "schemaName.keyword": "blocco" } },
                     { "term": { "schemaName.keyword": "loghi" } },
                     { "term": { "schemaName.keyword": "ufficio" } },
                     { "term": { "schemaName.keyword": "contatto-rubrica" } }
                  ]
               }
            }
         }
      }
   }

   if (filters.filtri.length > 0 || (filters.period.al !== null && filters.period.dal !== null)) {
      if (filters.filtri.length > 0) {
         for (let filtro of filters.filtri) {
            filtro = filtro.split(':')

            switch (filtro[0]) {
               case 'TIPOLOGIA':
                  filtro[0] = 'schemaName.keyword';
                  break;
               case "PROGRAMMA":
                  filtro[0] = 'data.programma.iv.keyword';
                  break;
               case 'TOPIC':
                  filtro[0] = 'data.topic.iv.keyword';
                  break;
            }

            let padreFilter = filtro[0];
            let filterTerm = filtro[1];

            var obj = {};
            obj[padreFilter] = filterTerm;
            jsonObj.query.bool.filter.bool.should.push({
               "term": obj,
            });
         }
      }
      if (filters.period.al !== null && filters.period.dal !== null) {
         let dataDalString = new Date(filters.period.dal.toDateString())
         let dataAlString = new Date(filters.period.al.toDateString());

         let dataDal = dataDalString.getFullYear() + '-' + String((dataDalString.getMonth() + 1) + '').padStart(2, '0') + '-' + String(dataDalString.getDate()).padStart(2, '0');
         let dataAl = dataAlString.getFullYear() + '-' + String((dataAlString.getMonth() + 1) + '').padStart(2, '0') + '-' + String(dataAlString.getDate()).padStart(2, '0');

         jsonObj.query.bool.filter.bool.should.push({ "range": { "data.dataPubblicazione.iv": { "gte": dataDal, "lte": dataAl, "format": "yyyy-MM-dd" } } })
      }
   }
   else {
      jsonObj.query.bool.filter.bool.should = []
   }
   return jsonObj;
}

export async function getList(terms, who, from, limit, filters, sort) {
   let payload = getPayload(terms, who, from, limit, filters, sort);
   return axios.post(process.env.VUE_APP_ESEARCH_PROTOCOL + process.env.VUE_APP_ESEARCH + '/' + process.env.VUE_APP_ESEARCH_INDEX + '/_search',
      payload
      //{
      //   auth: { 
      //      username: process.env.VUE_APP_ESEARCH_CREDENTIALS_USER,
      //      password: process.env.VUE_APP_ESEARCH_CREDENTIALS_PASS
      //   }
      //}
   ).then(response => {
      const { total, hits } = response.data.hits;
      const aggregations = response.data.aggregations;
      return { total, aggregations, posts: hits.map(x => parseArticolo(x)) };
   })
}

export async function getListRubrica(terms, who, from, limit, filters, sort) {
   //let payload = getPayload(terms, who, from, limit, filters, sort);   
   let rubricaPayload = getRubricaPayload(terms, from, limit, filters, sort)
   return axios.post(process.env.VUE_APP_ESEARCH_PROTOCOL + process.env.VUE_APP_ESEARCH + '/' + process.env.VUE_APP_ESEARCH_INDEX + '/_search',
      rubricaPayload
      ,      //{
      //   auth: { 
      //      username: process.env.VUE_APP_ESEARCH_CREDENTIALS_USER,
      //      password: process.env.VUE_APP_ESEARCH_CREDENTIALS_PASS
      //   }
      //}
   ).then(response => {
      const { total, hits } = response.data.hits;
      const aggregations = response.data.aggregations;
      return { total, aggregations, posts: hits.map(x => parseContatto(x)) };
   })
}

export async function searchFiltered(whoVal, sortVal, fromVal, sizeVal) {
   let limit = sizeVal == '' ? 3 : sizeVal;
   let from = fromVal == '' ? 0 : fromVal;
   let who = whoVal;
   let sort = sortVal == '' ? [{ "data.dataPubblicazione.iv": { "order": "desc" } }] : sortVal;

   axios.post(process.env.VUE_APP_ESEARCH_PROTOCOL + process.env.VUE_APP_ESEARCH + '/' + process.env.VUE_APP_ESEARCH_INDEX + '/_search',
      {
         "size": limit,
         "from": from,
         "track_scores": true,
         "_source": {
            "excludes": ["timestamp", "appId", "createdBy", "actor", "version", "partition"]
         },
         "sort": sort,
         "query": {
            "bool": {
               "must": [{
                  "term": {
                     "schemaName": who
                  }
               },
               {
                  "term": {
                     "status": "published"
                  }
               }]
            }
         }
      },
      //{
      //   auth: { 
      //      username: process.env.VUE_APP_ESEARCH_CREDENTIALS_USER,
      //      password: process.env.VUE_APP_ESEARCH_CREDENTIALS_PASS
      //   }
      //}
   ).then(response => {
      const { total, hits } = response.data.hits;
      const aggregations = response.data.aggregations;
      return { total, aggregations, posts: hits.map(x => parseArticolo(x)) };
   })
}

export async function doSearch(terms, from, limit, filters, sort) {
   let payload = getPayloadSearch(terms, from, limit, filters, sort);

   return axios.post(process.env.VUE_APP_ESEARCH_PROTOCOL + process.env.VUE_APP_ESEARCH + '/' + process.env.VUE_APP_ESEARCH_INDEX + '/_search',
      payload
      //   {
      //      auth: { 
      //         username: process.env.VUE_APP_ESEARCH_CREDENTIALS_USER,
      //         password: process.env.VUE_APP_ESEARCH_CREDENTIALS_PASS
      //      }
      //   }
   ).then(response => {
      const { total, hits } = response.data.hits;
      const aggregations = response.data.aggregations;
      return { total, aggregations, posts: hits.map(x => parseArticolo(x)) };
   })
}

export function parseArticolo(response) {
   response = response._source;
   return {
      id: response.id,
      schemaName: response.schemaName !== undefined ? response.schemaName.toLowerCase() : (response.__typename !== undefined ? response.schemaName.toLowerCase() : null),
      dataCreated: getDate(response.created),
      lastModified: getDate(response.lastModified),
      dataPubblicazione: (response.data.dataPubblicazione ? getDate(response.data.dataPubblicazione.iv) : null),
      breve: (response.data.breve ? response.data.breve.iv : null),
      calendarioEventi: (response.data.calendarioEventi ? response.data.calendarioEventi.iv : null),
      titolo: (response.data.titolo ? response.data.titolo.iv : null),
      image: (response.data.immagine && response.data.immagine.iv ? getRisorsa(response.data.immagine.iv) : null),
      corpo: (response.data.corpo ? response.data.corpo.iv : null),
      slug: (response.data.slug ? response.data.slug.iv : null),
      tempoLettura: (response.data.tempoLettura ? response.data.tempoLettura.iv : null),
      topic: response.data.topic ? response.data.topic.iv : null,
      nomeProgramma: (response.data.nomeProgramma ? response.data.nomeProgramma.iv : null),
      programma: (response.data.programma ? response.data.programma.iv : null),
      programmaLink: (response.data.programmaLink !== undefined && Object.keys(response.data.programmaLink.iv[0]).length !== 0 ? response.data.programmaLink.iv : null),
      contenutoPadre: (response.data.contenutoPadre ? response.data.contenutoPadre.iv : null),
      obiettiviSpecifici: (response.data.obiettiviSpecifici ? response.data.obiettiviSpecifici.iv : null),
      priorita: (response.data.priorita.iv ? response.data.priorita.iv : null),
      progettiStrategiciOriginal: (response.data.progettiStrategici.iv !== undefined ? response.data.progettiStrategici.iv : null),
      progettiStrategici: (response.data.progettiStrategiciLink !== undefined ? response.data.progettiStrategiciLink.iv : (response.data.progettiStrategici !== undefined ? response.data.progettiStrategici.iv : null)),
      strutturaOrganizzativa: response.data.strutturaOrganizzativa.iv ? response.data.strutturaOrganizzativa.iv : null,
      video: (response.data.video.iv ? getRisorsaVideo(response.data.video.iv) : null)
   };
}

export function parseContatto(response) {
   response = response._source;
   return {
      id: response.id,
      schemaName: response.schemaName !== undefined ? response.schemaName.toLowerCase() : (response.__typename !== undefined ? response.schemaName.toLowerCase() : null),
      dataCreated: getDate(response.created),
      lastModified: getDate(response.lastModified),
      titolo: response.data.titolo.iv,
      cognome: response.data.cognome.iv,
      nome: response.data.nome.iv,
      emailList: response.data.emailList.iv,
      telefonoList: response.data.telefonoList.iv,
      localizzazione: response.data.localizzazione.iv,
      strutturaOrganizzativa: response.data.strutturaOrganizzativa.iv ? response.data.strutturaOrganizzativa.iv : null,
      slug: response.data.slug.iv,
   }
}


function getRisorsa(asset, card = false) {
   //asset image
   if (asset.image !== null && asset.image.length > 0) {
      return {
         src: (!asset.image[0].indexOf('http') == 0 ? process.env.VUE_APP_SQUIDEX_ASSETS + asset.image[0] : asset.image[0]) + (card == true ? '?width=800&height=450' : '?width=1280&height=720'),
         didascalia: asset.didascalia != null ? asset.didascalia : asset.titolo,
         descrizione: asset.descrizione,
         titolo: asset.titolo,
         fonte: asset.fonte,
      }
   }

   if (asset.imgUnsplash !== null && asset.imgUnsplash !== "") {
      return {
         src: asset.imgUnsplash + (card == true ? '&w=540&h=414&fit=crop' : '&w=1280&h=720&fit=crop'),
         didascalia: asset.didascalia != null ? asset.didascalia : asset.titolo,
         descrizione: asset.descrizione,
         titolo: asset.titolo,
         fonte: asset.fonte,
      }
   }

   if (asset.imgURL !== null && asset.imgURL !== '') {//asset imgURL
      return {
         src: asset.imgURL + (card == true ? '?width=800&height=450' : '?width=1280&height=720'),
         didascalia: asset.didascalia != null ? asset.didascalia : asset.titolo,
         descrizione: asset.descrizione,
         titolo: asset.titolo,
         fonte: asset.fonte,
      }
   }
}

function getRisorsaVideo(asset) {
   if (asset.imgURL === "" && asset.imgURL === null && (asset.video !== null || asset.videoURL !== null)) {
      return {
         videoURL: asset.videoURL !== null ? asset.videoURL : (asset.video[0].indexOf('http') == -1 ? process.env.VUE_APP_SQUIDEX_ASSETS + asset.video[0] : asset.video[0]) + '?width=800&height=450',
         posterSrc: (asset.image[0].indexOf('http') == -1 ? process.env.VUE_APP_SQUIDEX_ASSETS + asset.image[0] : asset.image[0]),
         titolo: asset.titolo,
         descrizione: asset.descrizione
      }
   }
   else if (asset.image !== null && asset.image.length > 0) {
      return {
         videoURL: asset.videoURL !== null ? asset.videoURL : (asset.video[0].indexOf('http') == -1 ? process.env.VUE_APP_SQUIDEX_ASSETS + asset.video[0] : asset.video[0]) + '?width=800&height=450',
         posterSrc: (!asset.image[0].indexOf('http') == 0 ? process.env.VUE_APP_SQUIDEX_ASSETS + asset.image[0] : asset.image[0]) + '?width=800&height=450',
         titolo: asset.titolo,
         descrizione: asset.descrizione
      }
   }
   else {
      return {
         videoURL: asset.videoURL !== null ? asset.videoURL : (asset.video[0].indexOf('http') == -1 ? process.env.VUE_APP_SQUIDEX_ASSETS + asset.video[0] : asset.video[0]),
         posterSrc: asset.imgURL,
         titolo: asset.titolo,
         descrizione: asset.descrizione
      }
   }
}