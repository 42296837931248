import {createStore} from "vuex";
import createPersistedState from "vuex-persistedstate";

export default createStore({
   plugins: [createPersistedState()],
   state: {
      setLastUpdated: '',
      mastheadMenu: [],
      mainMenu: [],
      footerMenu: [],
      miniFooterMenu: [],
      layout: [],
      pageId: {}
   },

   mutations: {
      setMastHeadMenu (state, arr){
         if(arr.length > 0){      
            state.mastheadMenu = arr;
         }
      },
      setMainMenu ( state, arr){
         state.mainMenu = arr;
      },
      setLastUpdated ( state, data){
         state.setLastUpdated = data;
      },
      setFooterMenu (state, arr){
         state.footerMenu = arr;
      },
      setMiniFooterMenu (state, arr){
         state.miniFooterMenu = arr;
      },
      setLayout (state, layout) {
         state.layout = layout         
      },  
      setPageId (state, id) {
         state.pageId = id         
      },             
      async setSearchArguments (state, arr) {
         state.argomenti = arr;
      },
   },

   getters: {
      getLastUpdated: state => {
         return state.setLastUpdated;
      },
      getMastHeadMenu: state => {
         return state.mastheadMenu;
      },      
      getMainMenu: state => {
         return state.mainMenu;
      },
      getFooterMenu: state => {
         return state.footerMenu;
      },
      getMiniFooterMenu: state => {
         return state.miniFooterMenu;
      },
      getLayout: state => {
         return state.layout;
      }, 
      getPageId: state => {
         return state.pageId;
      }
   }   
});
